import React from "react"
import { Layout } from "../templates/Layout"
import { Seo } from "../components/helmets/Seo"
import { HeadlineBlock } from "../components/articles/HeadlineBlock"
import { Typography, Box } from "@material-ui/core"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <HeadlineBlock title="404: Not found" />
    <Box my={2}>
      <Typography>ページが見つからないよ</Typography>
    </Box>
  </Layout>
)

export default NotFoundPage
